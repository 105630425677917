import { useEffect, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { useDotButton } from "components/globals/ui/carousel/EmblaCarouselDotButton";
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from "components/globals/ui/carousel/EmblaCarouselArrowButtons";
import { Button } from "components/globals/ui/input/Button";
import { NavLink } from "react-router-dom";

const AUTOPLAY_INTERVAL = 10000; // 10 seconds

const MarketingGallery = ({ slides }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ 
    loop: true,
    align: "center",
  });

  // const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);
  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
    usePrevNextButtons(emblaApi);

  // Autoplay functionality
  const autoplay = useCallback(() => {
    if (!emblaApi) return;
    
    let intervalId = null;
    
    const startAutoplay = () => {
      intervalId = setInterval(() => {
        if (emblaApi.canScrollNext()) {
          emblaApi.scrollNext();
        } else {
          emblaApi.scrollTo(0); // Loop back to start
        }
      }, AUTOPLAY_INTERVAL);
    };

    // Start autoplay
    startAutoplay();
    
    // Stop autoplay on user interaction
    emblaApi.on("pointerDown", () => {
      clearInterval(intervalId);
    });
    
    // Resume autoplay after interaction ends
    emblaApi.on("settle", () => {
      clearInterval(intervalId);
      startAutoplay();
    });
    
    // Cleanup
    return () => clearInterval(intervalId);
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) autoplay();
  }, [emblaApi, autoplay]);

  return (
    <div className="relative w-full">
      {slides.length > 0 && (
        <div className="relative">
          <div className="embla w-full overflow-hidden" ref={emblaRef}>
            <div className="embla__container flex">
              {slides.map((slide, index) => (
                <div key={index} className="embla__slide flex-[0_0_100%] min-w-0 relative">

                  <div className="relative z-[1]">
                    <img
                      src={slide.image}
                      alt={`Gallery Slide ${index + 1}`}
                      className="hidden md:block w-full h-auto"
                    />

                    <img
                      src={slide.mobileImage}
                      alt={`Gallery Slide ${index + 1}`}
                      className="block md:hidden w-full h-auto"
                    />
                  </div>

                  <div className="absolute z-[2] inset-0 flex items-center">
                    <div className="w-main">

                      <div className="ors-rich-text">
                        {slide.title && (
                          <h1>{slide.title}</h1>
                        )}

                        {slide.description && (
                          <p>{slide.description}</p>
                        )}

                      </div>

                      {slide.url && (
                        <NavLink className="mt-3" to={slide.url}>
                          <Button variant="primary">
                            <span className="inline-block ml-2">{slide.btnText}</span>
                          </Button>
                        </NavLink>
                      )}

                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Navigation Buttons */}
          {slides.length > 1 && (
            <div className="absolute z-10 bottom-3 right-3 w-auto p-2">
              <div className="absolute inset-0 z-[1] bg-black opacity-60 rounded-full"></div>
              <div className="relative z-[2] flex items-center">
                <div className="flex items-center shrink-0">
                  <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
                  <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
                </div>
              </div>
            </div>
          )}
          
        </div>
      )}
    </div>
  );
};

export default MarketingGallery;