import { React } from "react";

const ORSVersion = () => {
  return (
    <div className="inline-block bg-black text-white font-mono text-[14px] rounded-md">
			<span className="py-2 px-3 font-bold inline-block border-r-grey border-r-[1px]">
			v1.01
			</span>
			<span className="inline-block py-2 pl-6 pr-7">
			4/1/25
			</span>
    </div>
  );
};

export default ORSVersion;
