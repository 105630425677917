import { React } from "react"
import PSPage from "components/globals/ui/layout/Page"

const RoadmapModule = function({
  title,
  status
}) {
  return (
    <div className="mr-3 mb-3 grow">
      <div className="bg-guards rounded-md p-5" style={{
        opacity: status ? '1' : '0.2'
      }}></div>
      <label className="mt-2 text-[12px] uppercase font-bold tracking-[0.1em]">{title}</label>
    </div>
  )
}

const RoadmapContainer = function({
  title,
  description,
  release,
  date,
  planning,
  design,
  development,
  testing,
  launch,
}) {
  return (
    <div className="p-xs sm:p-sm bg-[#ffffff] my-sm rounded-lg">
      <div className="ors-rich-text">
        <h2 className="text-2xl mb-2 !mt-0">{title}</h2>
        <p>{description}</p>
        <div className="inline-block bg-[#606060] text-white font-mono text-[14px] rounded-md mt-2 mb-6">
          <span className="pt-2 pb-0 sm:py-2 px-3 font-bold inline-block sm:border-r-grey sm:border-r-[1px]">
            Target Release: {release}
          </span>
          <span className="inline-block pt-0 pb-2 sm:py-2 px-3 sm:pl-6 sm:pr-7">
            Est. Date: {date}
          </span>
        </div>
      </div>
      <div className="flex items-center flex-wrap md:flex-nowrap w-full">
        <RoadmapModule title="Planning" status={planning} />
        <RoadmapModule title="Design" status={design} />
        <RoadmapModule title="Dev" status={development} />
        <RoadmapModule title="Testing" status={testing} />
        <RoadmapModule title="Launch" status={launch} />
      </div>
    </div>
    
  )
}

const Roadmap = () => {
  return (
    <>
      <PSPage>
        <h1 className="text-3xl font-bold mb-small my-md">Feature Roadmap</h1>

        <div className="mb-md">

          <RoadmapContainer
            title="Groups"
            description="Support for creating and managing groups with admins and members"
            release="1.1"
            date="April 2025"
            planning={true}
            design={true}
            development={true}
            testing={false}
            launch={false}
          />

          <RoadmapContainer
            title="Followers"
            description="Support for following other users, and relevant content show up in feeds."
            release="1.1"
            date="April 2025"
            planning={true}
            design={true}
            development={true}
            testing={false}
            launch={false}
          />

        </div>
      </PSPage>
    </>
    
  );
};

export default Roadmap;
