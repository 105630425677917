import React from 'react';
import { useAuth } from 'providers/AuthContext';
import {DashboardTemplate } from './Dashboard';
import {
  UpdateUsername,
  UpdateName,
  UpdateEmail,
  UpdateBio,
  UpdateProfile,
  UpdateBanner,
  UpdatePassword,
} from "components/globals/user/Settings";


export default function Settings() {
  const { userInfo, isFetching } = useAuth();

  return (
    <>
      { !isFetching && userInfo ? (
				<DashboardTemplate userInfo={userInfo} title="Settings & Preferences">
					
          <UpdateUsername userInfo={userInfo} />
          <UpdateName userInfo={userInfo} />
          <UpdateEmail userInfo={userInfo} />
          {/* <UpdateBio userInfo={userInfo} /> */}
          <UpdateProfile userInfo={userInfo} />
          <UpdateBanner userInfo={userInfo} />
          <UpdatePassword userInfo={userInfo} />

				</DashboardTemplate>
      ) : null }
    </>
  );
}